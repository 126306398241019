.main-history-container{
    height: 100%;
    background-color: #fff;
    width: 100%;
}
.price-history{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60px;
}
.price-history p{
    margin: 0;
    padding: 0;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.609);
}
.time-history{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
    position: relative;
    right: 0 !important;
}
.time-history p{
    margin: 0;
    padding: 0;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.609);
}
.History-footer{
    color: #000;
    padding: 10px;
    margin: 0 auto;
    height: 15vh;

}
.History-footer ul{
    list-style: none;
    padding: 0;
    margin: 0;
    
}
.History-footer div{
    display: flex;
    justify-content: space-between;
}
.MuiMultiInputDateRangeField-root div{
    width: 120px !important;
}
.MuiMultiInputDateRangeField-root{
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center !important;
    height: 10%;
}

.time-history{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 24px;
}

.history_date_range{
    height: 5vh;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.history_date_range button{
    text-transform: none;
    padding: 0px 5px;
}
.history-table td, .history-table th{
    padding: 5px 10px;
}