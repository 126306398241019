@tailwind base;
@tailwind components;
@tailwind utilities;
body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* styles.css */
:root {
  /* Light theme colors */
  --background-color: #fff;
  --text-color: #333;
  --header-color: #f0f0f0;
}

[data-theme="dark"] {
  /* Dark theme colors */
  --background-color: #333;
  --text-color: #fff;
  --header-color: #222;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

header {
  background-color: var(--header-color);
}

body {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}