.main-order{
    background-color: #fff;
    width: 100vw;
    color: #000000d9;
    height: 100%;
    font-weight: 500;
}
.acc-metric-order{
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content:  space-between;
    height: 10vh;
    color: #000 !important;
}
.acc-metric-order ul{
    list-style: none;
    padding: 0;
}
.acc-positions-order td,.acc-positions-order th{
    text-align: left !important;
}

.main-order-header{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 5px 0px;
}
.acc-details-order{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.acc-details-order p{
    margin: 0;
    font-weight: bold;
}
.data-balance{
    text-align: right;
}

.positions-opened td{
    margin: 0 10px !important;
    padding:  10px !important;
}
.actionSheet-Trade{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.actionSheet-Trade button{
    margin: 10px 0px;
    text-transform: none;
}
.Selected-position{
    font-size: 12px;

}
.Selected-position p{
    margin: 5px 0;
    color: rgba(0, 0, 0, 0.6);
}
.partials{
    margin-top: 10px;
}