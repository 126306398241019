.main-container{
  width: 100vw;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
.bottom-navigation{
  width: 100vw;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  height: 10vh;
}



