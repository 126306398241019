.main-acc-container{
    width: 100vw;
    background-color: #fff;
    color: #000;
    height: 100vh;
    padding: 0;
    margin: auto;

}
.loggedin{
    text-align: center;
    background-image: linear-gradient(to right, #7858C5, #F75353, #7858C5);
    height: 100%;
}

.first_Container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
}
.first_Container > ul:first-child{
    text-align: left;
}
.first_Container > ul:last-child{
    text-align: right;
}
.profit_loss_winrate{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}