.trading {
    height: 96vh;
    background-color: #fff;
    width: 100vw;
    color: #000;
    text-align: center;
}

.watchlist {
    height: 96vh;
    background-color: #fff;
    color: #000 !important;
    width: 100vw;
}

.Slide-To-Trade,
.Slide-To-delete,
.Slide-To-add {
    width: 60px;
    height: 60px;
    position: relative;
    top: -30px;
    color: #fff !important;
    border-radius: none !important;
    border: none;
    cursor: pointer;
}

.Slide-To-Trade {
    background-color: rgba(0, 0, 255);
}

.Slide-To-delete {
    background-color: rgb(255, 0, 0);
}

.Slide-To-add {
    background-color: rgba(102, 102, 102);
}


.Market-Execution input {
    text-align: center;
    width: 100px;
    height: 10px;
}


.lot-and-sl-tp {
    width: 200px;
}

.lot-and-sl-tp input {
    width: 200px;
    height: 20px;
}

.inputsearch input {
    height: 15px;
}

.pair-trade-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
}

.pair-trade-header-main {
    width: 90%;
}

.lot-size-part {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.sl-tp-upTodate {
    display: flex;
    flex-direction: column;
}
.price, .sl,.tp{
    display: flex;
    justify-content: space-between;
    margin: 10px 20px;
}
.price div,.sl div,.tp div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.sl input,.tp input, .price input{
    height: 20px;
    border: none;
    outline: none;
}

.sl input:focus, .tp input:focus,.price input:focus {
    box-shadow: none; 
  }

.price-btn{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 10px;
}
.price-btn span{
    font-weight: bold;
}
.btn-entry{
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-entry button{
    width: 50% !important;
    border-radius: 0px !important;
    text-transform: none !important;
}
.btn-entry-pending button{
    width: 100% !important;
    border-radius: 0px !important;
    text-transform: none !important;
    background-color: #000000d0 !important;
}