.main-chart{
    height: 80vh;
    width: 100%;
}
.chartTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    margin: 5px 15px;
}
.btn-entry input{
    text-align: center;
}

.css-19kzrtu{
    padding: 0px !important;
}